body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.width2percent {
  width: 2%;
}
.tableButton {
  margin-left: 15px;
}
#BackDropModalLayout {
  background-color: black;
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
  opacity: 0.5;
}

.addDeparmentButton {
  /* position: absolute; */
  margin-top: 10px;
  margin-bottom: 20px;
}

.columnHeaderSmall {
  min-width: 50px;
  text-align: "center";
}

.columnHeader {
  min-width: 100px;
  text-align: "center";
}

.columnHeaderMedium {
  min-width: 250px;
  text-align: "center";
}

.columnHeaderBig {
  min-width: 400px;
  text-align: "center";
}

.columnBreakSpaces {
  white-space: break-spaces !important;
  vertical-align: top !important;
}

.filterArea {
  position: absolute;
  right: 30px;
  top: 21px;
  font-weight: bold;
}

.centerImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.centerText {
  text-align: center;
}

.centerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowCustom {
  display: flex;
}
.inputLayout {
  padding: 50px;
  padding-right: 0px;
}

.titleConnection {
  text-align: center;
  border-bottom: 1px solid #e6e9f4;
  padding-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
}
.layoutCircle {
  background: #6d6db2;
  width: 45px;
  height: 45px;
  border-radius: 38px;
  margin-top: 3px;
  margin-left: -5px;
  z-index: 900;
}
.circleText {
  z-index: 1000;
  color: white;
  margin-left: 9px;
  font-size: 20px;
  padding-top: 4px;
}
.connectionString {
  margin-top: 20px;
  padding: 10px;
}
.dbConnectedIcon {
  color: #349034;
  font-size: 36px;
  margin-top: 5px;
}

.dbDisconnectedIcon {
  font-size: 36px;
  margin-top: 5px;
}

.fullHeight {
  min-height: 780px;
}
.displayInlineblock {
  display: inline-block;
}
.mainTitle {
  font-size: 35px;
}

.mainLayout {
  width: 400px;
  height: 400px;
  border: 10px solid #1a50ca;
  position: absolute;
  border-radius: 300px;
  margin-top: -90px;
  margin-left: -45px;
}

.circle-text {
  width: 120%;
  padding: 10px;
}
.circle-text:after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  border: 10px solid #92e3fb;
}
.circle-text div {
  float: left;
  width: 100%;
  padding-top: 35%;
  line-height: 1em;
  margin-top: -0.5em;
  text-align: center;
  color: black;
  font-size: 28px;
}
.brick1 {
  background-color: #349034;
}

.form-password-toggle {
  margin-bottom: 10px;
}
.form-check-label {
  margin-left: 10px !important;
}

.mainTitledataProfiling {
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #d9dee3;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.layoutPadding {
  padding: 0px 40px 40px 40px;
}
.layoutPadding40 {
  padding: 0px 35px 35px 35px;
}

.tabButtonActive {
  background-color: #337ab7;
  margin-right: 20px;
  padding: 7px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.tabButton {
  margin-right: 20px;
  padding: 7px;
  cursor: pointer;
}
.characteristics {
  background: #ede2e2;
  display: inline-block;
  color: #ff1d1d;
  padding: 3px;
  font-size: 15px;
  border-radius: 4px;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.variableTitle {
  font-size: 22px;
  color: #337ab7;
}

.redText {
  color: red;
}

.selectedConnectionstring {
  background: #697a8d38;
  border-radius: 10px;
}

.bottom_right {
  bottom: 0;
  right: 0;
  margin-left: 15px;
}

.sideBarButtons {
  position: absolute;
  bottom: 2%;
  left: 19%;
}
.testConnectionButton {
  width: 202px;
  margin-top: 14px;
  background: green !important;
  color: white;
  border-color: green !important;
}

.importButton {
  width: 202px;
  margin-bottom: 14px;
  background: green !important;
  color: white;
  border-color: green !important;
}


.deleteConectionButton {
  background: red !important;
  color: white;
  border-color: red !important;
}

.actionsButtons {
  position: absolute;
  bottom: 5%;
  right: 30px;
}

.dropdownDatabaseTypeIcon {
  position: absolute;
  right: 8px;
  font-size: 27px;
  margin-top: 5px;
}

.bussinesType {
  display: inline-block;
  text-align: center;
}

.textAlingRight {
  text-align: right;
}

.textAlingRightDq {
  text-align: right;
  margin-top: 15px;
}

.textAlingCenter {
  text-align: center;
}

.buttonRules {
  font-size: 12px;
  background: #0090ff;
  padding: 7px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 7px;
  color: white !important;
}
.card-body-new {
  padding-bottom: 35px;
}

.buttonMargin {
  margin-right: 15px;
}

.bussinesCounter {
  margin-left: -20px;
}
.noMarginTitle {
  margin-bottom: 2px;
  color: black !important;
  font-size: 15px;
}

.searchBussinesLayout {
  margin-left: 20px;
  background: #f5f5f9;
  padding: 5px;
  border-radius: 11px;
  margin-bottom: 10px;
}

.searchInputLayout {
  background: #f5f5f9;
}
.miniTabOption {
  font-size: 11px;
  padding: 4px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid black;
  margin-left: 0px;
  display: inline-block;
}
.miniTabOption.active {
  background: #233446;
  color: white;
}

.miniTabOption.first {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.miniTabOption.last {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.displayInlineBlock {
  display: inline-block;
}

.paddingTitle {
  padding: 17px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 1px;
  font-size: 13px;
}
.showTitle {
  margin-right: 10px;
}
.marginBottom {
  margin-bottom: 20px;
}

.padding20 {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.bookIcon {
  font-size: 31px;
  margin-top: -22px;
}

.ruleID {
  font-size: 12px;
}

.versionsCounter {
  font-size: 12px;
}

.tagBussines {
  font-size: 12px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background: #b00505;
  color: white;

  border-radius: 5px;
  margin-left: 20px;
}

.marginLeft25 {
  margin-left: 25px;
}
.currentVersionIcon {
  margin-right: 12px;
}


.activeVersion {
  color: green;
}

.inactiveVersion {
  color: grey;
}

.disabledVersion {
  color: black;
}

.versionTitle {
  font-size: 13px;
  font-weight: bold;
}

.lastUpdated {
  font-size: 13px;
  margin-left: 5px;
  top: 20px;
  padding-left: 23px;
}

.buttonBussinesAction {
  font-size: 13px;
  padding: 7px;
  background: #233446;
  color: white;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.subText {
  font-size: 13px;
  margin-right: 20px;
}

.addNewtagBussines {
  font-size: 12px;
  border: 1px solid #233446;

  color: black;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  margin-left: 20px;
}

.activeCircle {
  color: green;
}

.inactiveCircle {
  color: gray;
}

.blockedCircle {
  color: black;
}

.sortbyName {
  padding-left: 34px;
  padding-right: 34px;
}
.sortByMainArea {
  margin-bottom: 5px;
}

.colorBlack {
  color: black;
}

#js-licensing {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #34445b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6980a8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #566d96;
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: #6b7280;
  color: #ffffff !important;
  opacity: 1;
}

.e-pager .e-numericitem.e-currentitem {
  border: 1px solid #ffffff !important;
  padding: 3.5px 11px 4px 10px;
}

ul.horizontal {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.logoutMenu {
  display: inline-block;
  margin-left: 28px;
}

.mainLogoLogout {
  padding-top: 15px;
  border-bottom: 1px solid rgb(242 67 10 / 15%);
  margin-bottom: 10px;
  text-align: center;
  background: white;
}

a {
  color: #566a7f !important;
}

a:hover {
  color: #f2430a !important;
}

.menu-icon {
  color: #f2430a !important;
}

.mainLogoLogoutArea {
  background-color: white;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.authentication-wrapper {
  min-height: 0px !important;
}

.bg-menu-theme .menu-inner > .menu-item.active:before {
  background-color: #f2430a !important;
}

.bg-menu-theme .menu-inner > .menu-item.active > .menu-link {
  color: #f2430a !important;
  background-color: rgb(255 176 105 / 16%) !important;
}

.menu .app-brand.demo {
  height: 157px !important;
}

.btn-primary {
  background-color: #f2430a !important;
  border: 1px solid f2430a;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(255 111 105 / 59%) !important;
}
.btn-primary:hover {
  background-color: #d33704 !important;
  border: 1px solid f2430a;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(255 111 105 / 59%) !important;
}

.btn-outline-primary {
  color: #f2430a !important;
  border-color: #f2430a !important;
  background: transparent !important;
}
.btn-outline-primary:hover {
  color: white !important;
  border-color: white !important;
  background: #f2430a !important;
}

.form-check-input:checked,
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #f2430a !important;
  border-color: #f2430a !important;
  box-shadow: 0 2px 4px 0 rgb(242 67 10 / 19%) !important;
}

.iframeArea {
  height: 500px;
}

.iframeHome {
  width: 100%;
  height: 100%;
}

.circleInfo {
display: inline-block;
width: 200px;
height: 200px;

margin-bottom: 30px;
border-radius: 300px;
border: 5px solid blue;
}

.info{
  display: grid;
  padding: 1rem;
  grid-template-columns: 50% 1fr;
  gap: 6rem;
  align-items: center;
  width: 100%;
  height: 100%;
  font: 500 100%/1.5 system-ui;
}

.infoImg{
  max-width: 100%;
  height: auto;
}

.form-select-display {
  display: revert !important;
}

.dataProfilingDropdown{
  text-align: right;
  padding-top: 20px;
  padding-right: 45px;
}

.btn-outline-primary input:checked{
  background-color: #1a50ca !important;
}
.btn-outline-primary:checked{
  background-color: #1a50ca !important;
}
.radioGroupChecked{
  color: white !important;
  border-color: #1f2937 !important;
  background: #1f2937 !important;
}

.radioButtonUnchecked {
  border-color: #1f2937 !important;
  color: black !important;
}

.btn-group label.btn-outline-primary:hover{
  border-color: #1f2937 !important;
}

.isFavorite{
  color: #f2430a ;
}
#layout-navbar {
  z-index: 200;
}
#layout-navbar-withoutuser {
  z-index: 100;
}
.pageTitle {
  position: absolute;
  left:45%;
}

.addNewBussinesRuleTag {
  font-size: 13px;
  padding-left: 20px;
  font-weight: bold;
  cursor: pointer;
}
.existingNewBussinesRuleTag {
  font-size: 13px;
  margin-left: 20px;
  font-weight: bold;
  padding:5px;
  border-radius: 5px;
}

.fontSize14 {
  font-size: 14px !important;
}

.iconMargin {
  margin-right:5px;
}

.saveButtonRule{
  position: fixed;
  bottom: 3rem;
  right: 1.625rem;
  z-index: 999999;
  box-shadow: 0 1px 10px 1px #a0a5a0;
  color: white;
  color: white !important;
}

@import url("https://fonts.googleapis.com/css?family=Roboto");
@-webkit-keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
            transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
            transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
            transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
            transform: translatey(0px) scale(1);
    opacity: 1;
  }
}
@keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
            transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
            transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
            transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
            transform: translatey(0px) scale(1);
    opacity: 1;
  }
}
* {
  margin: 0;
  padding: 0;
}

html, body {
  background: #eaedf2;
  font-family: 'Roboto', sans-serif;
}

.floating-container {
  position: fixed;
  width: 100px;
  height: 100px;
  bottom: 20px;
  right: 0;
  margin: 35px 25px;
}
.floating-container:hover {
  height: 300px;
}
.floating-container:hover .floating-button {
  box-shadow: 0 10px 25px rgba(44, 179, 240, 0.6);
  -webkit-transform: translatey(5px);
          transform: translatey(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.floating-container:hover .element-container .float-element:nth-child(1) {
  -webkit-animation: come-in 0.4s forwards 0.2s;
          animation: come-in 0.4s forwards 0.2s;
}
.floating-container:hover .element-container .float-element:nth-child(2) {
  -webkit-animation: come-in 0.4s forwards 0.4s;
          animation: come-in 0.4s forwards 0.4s;
}
.floating-container:hover .element-container .float-element:nth-child(3) {
  -webkit-animation: come-in 0.4s forwards 0.6s;
          animation: come-in 0.4s forwards 0.6s;
}
.floating-container .floating-button {
  position: absolute;
  width: 65px;
  height: 65px;
  background: #22c55e;
  bottom: 0;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  color: white;
  line-height: 65px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(44, 240, 152, 0.6);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.floating-container .float-element {
  position: relative;
  display: block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 15px auto;
  color: white;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  z-index: 0;
  opacity: 0;
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
}
.floating-container .float-element .material-icons {
  vertical-align: middle;
  font-size: 16px;
}
.floating-container .float-element:nth-child(1) {
  background: #42A5F5;
  box-shadow: 0 20px 20px -10px rgba(66, 165, 245, 0.5);
}
.floating-container .float-element:nth-child(2) {
  background: #4CAF50;
  box-shadow: 0 20px 20px -10px rgba(76, 175, 80, 0.5);
}
.floating-container .float-element:nth-child(3) {
  background: #FF9800;
  box-shadow: 0 20px 20px -10px rgba(255, 152, 0, 0.5);
}

.ScrollViewDatabaseConnection{
  max-height: 470px;
  overflow: auto;
  overflow-x: hidden;
}

.ScrollViewMenu{
  max-height: 1200px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #972d0a; 
} */

/* width */
::-webkit-scrollbar {
  width: 4px;
}

.data_domains_select{
  margin-left: 14px;
}

.data_domains_select_dashboard{
  margin-left: 14px;
  width: 25%;
  margin-top: 25px;
}

.spinner-inputdata{
  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 100px;
  z-index: 200;
  margin-top: 500px;
}

.dataQualityRulesNum {
  color: #ff5900;
  padding-bottom: 4px;
}



@media screen and (max-width: 1264px) {
  .textAllignResponsiveStatus{
    text-align: center;
  }
}

.app-brand .layout-menu-toggle {
  background-color: #ff5900;
}

.img-homepage {
  border-radius: 99%;
}